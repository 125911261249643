import { SectionHeader as SectionHeaderUI } from "@business-finland/wif-ui-lib/dist/SectionHeader";

import useEmployerSite from "../../../contexts/EmployerSiteContext";

export interface SectionHeaderProp {
  heading: string;
  anchorTag?: string;
  leadText?: string;
  backgroundColor?: "white" | "color";
}

export function SectionHeader({
  heading,
  anchorTag,
  leadText,
  backgroundColor,
}: SectionHeaderProp): JSX.Element {
  const { isEmployerSite } = useEmployerSite();

  return (
    <SectionHeaderUI
      id={anchorTag}
      leadText={leadText}
      backgroundColor={
        backgroundColor !== "white"
          ? isEmployerSite
            ? "gray"
            : "lightBlue"
          : "white"
      }
    >
      {heading}
    </SectionHeaderUI>
  );
}
